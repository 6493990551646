import React, { useCallback, useEffect, useRef, useState } from "react";
import Poppins from "../src/assets/fonts/Poppins/Poppins-Regular.ttf";
import PoppinsBold from "../src/assets/fonts/Poppins/Poppins-ExtraBold.ttf";
import PoppinsLight from "../src/assets/fonts/Poppins/Poppins-Light.ttf";
import RatingScale from "./components/RatingScale";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { SpiderChart } from "./components/SpiderChart"; // Adjust the import path to where your SpiderChart is located
import Swal from "sweetalert2";
import ReportComponent from "./components/ReportComponent";
import MaskedInput from 'react-text-mask';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { createRoot } from "react-dom/client";
import moment from "moment";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import axios from "axios";
import NavButtons from "./components/NavButtons";
import { Swiper as SwiperClass } from "swiper";
import questionsDB from "./questions.json";
import texts from "./texts.json";
import "./App.css";


import {
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,  
  Input,  
  InputLabel,  
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  ThemeProvider,
  Typography,
  createTheme,
  styled,
} from "@mui/material";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";

const idiomas = texts.idiomas;

const introVideo = require("./assets/intro_vertical.mp4");
const evoFooterLogo = require("./assets/logo-rodape.png");
const img_intro = require("../src/assets/img_intro.jpg");
const img_final = require("../src/assets/img_final.jpg");
const img_whats = require("../src/assets/whats.png");
type ReportEntry = {
  soma: number;
  qualificacao: string;
};

// Define the Report type, which represents the structure of the entire report object
type Report = {
  [area: string]: ReportEntry;
};

export default function App() {
  const swiperRef = useRef<SwiperClass>(null);
  const swiperQuizz = useRef<SwiperClass>(null);
  const inputRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [answers, setAnswers] = useState<any>({});
  const [chartData, setChartData] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [defaultLang, setDefaulLang] = useState<any>("pt");
  const [gift, setGift] = useState("");
  const [showNav, setShowNav] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const [userPhone, setUserPhone] = useState();
  const [userId, setUserId] = useState<Number>();
  const [userName, setUsername] = useState();
  const [fileLink, setFileLink] = useState();
  const [isExploding, setIsExploding] = React.useState(true);
  const [report, setReport] = useState<Report>();
  const { width, height } = useWindowSize();
  const [animationStyle, setAnimationStyle] = useState({});
  const [swiperIndex,setIndex] = useState(0);
  const [formData, setFormData] = useState<any>();
  const [nomeEvento, setNomeEvento] = useState("");


  const [formValues, setFormValues] = useState({
    firstname: "" || undefined,
    fullName: "" || undefined,
    telephone: "" || undefined,
    phone: "" || undefined,
    tipo_de_negocio: "" || undefined,
    grafico_quizz: "" || undefined,
    email: "" || undefined,
    businessType: "" || undefined,
    ja_e_cliente: undefined,
    ja_e_cliente_evo_: undefined,
    pontos_fortes: "" || undefined,
    pontos_de_atencao: "" || undefined,
    desafios: "" || undefined,
  });

   const todosTextos:any =  idiomas.find((obj) => obj.idioma === defaultLang)


  //@ts-ignore
  const handleInputChange = async (areaIndex, perguntaIndex, val) => {
    //@ts-ignore
    const questionId = `${questions.content[areaIndex].area}-${perguntaIndex}`;
    // console.log(questions.content[areaIndex].perguntas[perguntaIndex],"QUESTIONID");
    setAnswers((prevAnswers: any) => {
      const updatedAnswers = { ...prevAnswers, [questionId]: val };
      console.log(updatedAnswers); // Debugging line to inspect updates
      return updatedAnswers;
    });
  };

  const handlePrev = useCallback(async () => {
    if (swiperQuizz && swiperQuizz.current)
      await swiperQuizz.current.slidePrev(1000);
  }, []);

  const handleNext = useCallback(async () => {
    if (swiperQuizz && swiperQuizz.current)
      await swiperQuizz.current.slideNext(1000);
  }, []);

  const handleNextQuizz = useCallback(async () => {
    if (swiperQuizz && swiperQuizz.current)
      await swiperQuizz.current.slideNext(1000);
  }, []);

 
  useEffect(() => {


    const urlParams = new URLSearchParams(window.location.search);
    const userIdFromUrl:any = urlParams.get('userId');
    if (userIdFromUrl) {
      setUserEmail(userIdFromUrl);
      searchUser(userIdFromUrl);
    }

    if (swiperRef.current) {
      swiperRef.current.on("slideChange", () => {
        if (swiperRef && swiperRef.current)
          setCurrentIndex(swiperRef.current.realIndex);
      });
    }

   
    // Cleanup the event listener
    return () => {
      if (swiperRef.current) {
        swiperRef.current.off("slideChange");
      }
    };
  }, [swiperRef.current?.realIndex]);

  
  useEffect(() => {
    pickRandomItem();
    if (swiperRef.current) {
      swiperRef.current.slideTo(currentIndex, 0, false);
    }
  }, [currentIndex, answers]);



  const getForm = (id:string) => {
    const options = {
      method: 'POST',
      url: 'https://scan360-backend.azurewebsites.net/getFields',
      headers: {
        cookie: 'ARRAffinity=d6e7ae766d6a2701cf079eade52fcf281dece87f221d9cfe74e21b7013b05268; ARRAffinitySameSite=d6e7ae766d6a2701cf079eade52fcf281dece87f221d9cfe74e21b7013b05268',
        'Content-Type': 'application/json',
      },
      data: {properties: {formId: id}}
    };

    axios.request(options).then((response) => {
      setFormData(response.data); // Store the response
      return response.data;
    }).catch((error) => {
      console.error(error);
    });
  };

  const setLanguage = (lang: any) => {
    setDefaulLang(lang);
    //@ts-ignore
    const search: any = questionsDB.idiomas.find(
      (item) => item.idioma === lang
    );
    setQuestions(search);
    handleNextQuizz();
  };

  // COMPONENTES CUSTOMIZADOS
  
  const QuizzHeader = styled(Typography)({
  fontSize: "1rem",
  marginTop:"1.5rem",
  marginBottom: "10px",
  "@media (min-width: 600px)": {
    fontSize: "1.1rem",
  },
  "@media (min-width: 960px)": {
    fontSize: "1.2rem",
  },
  });

 
  const LangBtn = styled(Button)(({ theme }) => ({
    padding: theme.spacing(2),
    borderRadius: 0,
    fontSize: 32,
    fontWeight: "bold",
    color: "rgba(100, 100, 100, 0.85)",
  }));

  const ResultsBtn = styled(Button)(({ theme }) => ({
    padding: "10px 30px",
    borderRadius: 30,
    fontSize: 15,
    fontWeight: "bolder",
    backgroundColor: "white",
    color: "rgba(100, 100, 100, 1)",
    "&&:hover": {
      // Bottom border on hover
      backgroundColor: "#c7c7c7",
    },
  }));


  const DiagnosticBtn = styled(Button)(({ theme }) => ({
    padding: "5px 20px",
    borderRadius: 30,
    fontSize: 22,
    fontWeight: "bolder",
    backgroundColor: "#8061FF",
    color: "rgba(0, 0, 0, 1)",
    "&:hover": {
      backgroundColor: "#6750A4", // Hover background color
      // Optional: Change the color property if you also want to change the text color on hover
      color: "#FFFFFF", // Text color on hover
    },
  }));

  const buttonSwiper = () => {
    return (
      <NavButtons
        showNav={showNav}
        handlePrev={handlePrev}
        handleNext={handleNext}
        top={30}
      />
    );
  };

  // configs de tema : material-ui

  const theme = createTheme();
  //@ts-ignore
  theme.typography.h1 = {
    fontSize: "2.0rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.4rem",
    },
  };
  theme.typography.body1 = {
    fontSize: "1.1rem",
    fontFamily: "PoppinsLight",
  };
  theme.typography.body2 = {
    fontSize: "1.3rem",
    fontFamily: "PoppinsLight",
  };

  function checkAllQuestionsAnswered() {
    const allQuestions = questionsDB.idiomas.flatMap((language) => {
      if (language.idioma === defaultLang) {
        return language.content.flatMap((section) =>
          section.perguntas.map((_, index) => `${section.area}-${index}`)
        );
      } else {
        return [];
      }
    });
    return allQuestions.every(
      (questionKey) =>
        answers.hasOwnProperty(questionKey) &&
        answers[questionKey] !== null &&
        answers[questionKey] !== undefined
    );
  }

  function countAnsweredQuestions() {
    const allQuestions = questionsDB.idiomas.flatMap(language => {
      if (language.idioma === defaultLang) {
        return language.content.flatMap(section =>
          section.perguntas.map((_, index) => `${section.area}-${index}`)
        );
      } else {
        return [];
      }
    });
  
    const answeredQuestions = allQuestions.filter(questionKey => 
      answers.hasOwnProperty(questionKey) && 
      answers[questionKey] !== null && 
      answers[questionKey] !== undefined
    );
  
    return answeredQuestions.length;
  }

  function pickRandomItem() {
    const items = todosTextos?.textos.find((obj: { tela: string; }) => obj.tela === "sorteio")
    ?.content
  
    const randomIndex = Math.floor(Math.random() * items.length);
   
    setGift(items[randomIndex]);
    return items[randomIndex];
  }
  type ChartData = {
    subject: string;
    A: number;
    fullMark: number;
  };
  async function generateChartData(source: { [key: string]: number }): Promise<ChartData[]> {
    const capitalize = (s: string) =>
      s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    const categories: { [key: string]: number[] } = {};
    const report = await gerarRelatorioPorArea();
    console.log(report,"####REPORT");
    setReport(report);
    Object.entries(source).forEach(([key, value]) => {
      const [category] = key.split("-");
      categories[category] = categories[category] || [];
      categories[category].push(value);
    });
    const chartData: ChartData[] = Object.entries(categories).map(
      ([category, scores]) => {
        const averageScore =
          scores.reduce((sum, score) => sum + score, 0) / scores.length;
        const sum = report[category].soma;

        return {
          subject: `${capitalize(category)} (${sum} pts)`,
          A: averageScore,
          fullMark: 30,
          textos: todosTextos?.textos.filter((obj: { tela: string; },index: number) => { if(obj.tela === "resultado") return obj})[1]
         }
        }
    );
    //@ts-ignore
    setChartData(chartData);
   
    return chartData;
  }

  function handleFormChange(e: any) {
    // e.prevendDe();
 
    const { name, value } = e.target as HTMLInputElement; // Safe if only accessing shared properties
    setFormValues((formValues) => ({
      ...formValues,
      [name]: value,
    }));
  }

  async function handleDownload(){
    setBtnLoading(true);
    await generateChartData(answers).then(async(chartDados)=>{
      await uploadGrafico(userId, chartDados);
      setBtnLoading(false);
    });
  }

  async function generatePdfFromHtml(
    pdfPath: string,
    chartDados?: any
  ): Promise<void> {
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: [438.67, 290.5]
    });
    const container = document.createElement("div");
    
    document.body.appendChild(container);
    const root = createRoot(container);
    root.render(<SpiderChart data={chartDados} />);
    await new Promise((resolve) => setTimeout(resolve, 2500));
    const chartCanvas = await html2canvas(container, {
      useCORS: true,
      allowTaint: true,
    }).then();
    const imgChart = chartCanvas.toDataURL("image/png");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const margin = 20; // Margin from the page edges
    const footerHeight = 30; // Height of the footer area
    const availableHeight = pdfHeight - footerHeight - 2 * margin; // Available height for the chart
    const chartAspectRatio = chartCanvas.width / chartCanvas.height;
    const chartWidth = availableHeight * chartAspectRatio; // Calculate chart width based on its aspect ratio
    const chartXPosition = (pdfWidth - chartWidth) / 2; // Center the chart horizontally
    const chartYPosition = margin; // Position the chart below the top margin
  
    pdf.addImage(
      imgChart,
      "JPEG",
      chartXPosition,
      chartYPosition,
      chartWidth,
      availableHeight,
      undefined,
      'FAST'
    );
    container.style.display = 'none';
    const relatorioPorArea = await gerarRelatorioPorArea();
    console.log(relatorioPorArea,"###########");
    const dynamicSections:any = Object.entries(relatorioPorArea).reduce((acc:any, [key, value]) => {
      //@ts-ignore
      const qualificacao:any = value?.qualificacao;
      if (!acc[qualificacao]) {
        acc[qualificacao] = [];
      }
      acc[qualificacao].push(key);
      return acc;
    }, {});
    
    // Define colors for the bullets dynamically, including "DESAFIOS"
    const dynamicColors:any = {
      "DESAFIOS": "#FF0000", // Red for challenges
      "PONTOS DE ATENÇÃO": "#CC9900", // Dark Yellow
      "PONTOS FORTES": "#008000", // Green
      // Add more categories and colors as needed
    };
    
    // Start position for the report content
    const reportStartY = chartYPosition + (availableHeight / 1.2);
    const columnWidth = pdfWidth * 0.3; // 30% of the page width
    
    pdf.setFontSize(12);
    
    Object.entries(dynamicSections).forEach(([section, items], index) => {
      const sectionY = reportStartY + index * 15; // Adjust spacing between sections
      const color = dynamicColors[section] || "#000000"; // Default to black if no color defined
      pdf.setTextColor(color); // Set text color to the section-specific color
      pdf.setFillColor(color); // Set fill color for the bullet
    
      // Draw a filled circle as a bullet
      pdf.circle(15, sectionY - 2, 2, 'F'); // Adjust position to align with the text
    
      pdf.setFont("helvetica", "bold"); // Set font to bold for section titles
      pdf.text(section, 20, sectionY); // Offset text by 20 mm from the left margin
    
      pdf.setFont("helvetica", "normal"); // Set font to normal for items
      pdf.setFontSize(10); // Set font size for items
      //@ts-ignore
      const itemsText = items.join(", "); // Join items with commas
      pdf.text(itemsText, 20, sectionY + 5); // Position items text slightly below the section title
    });
    // Footer content
    const footerYPosition = pdfHeight - footerHeight + 5;
    pdf.setTextColor("#000000"); // Set text color to black for footer
    pdf.text(`${userName}`, 10, footerYPosition);
    pdf.text(`Email: ${userEmail}`, 10, footerYPosition + 10);
  
    // Add logo to the footer
    const logoWidth = 30;
    const logoHeight = 13;
    pdf.addImage(
      evoFooterLogo,
      "PNG",
      pdfWidth - logoWidth - margin,
      footerYPosition - logoHeight / 2,
      logoWidth,
      logoHeight
    );
  
    // Draw footer line
    pdf.setDrawColor("#8061FF"); // Line color
    pdf.line(10, footerYPosition - 15, pdfWidth - 10, footerYPosition - 15); // Adjust line length
    root.unmount();
    document.body.removeChild(container);
  
    const pdfBlob = pdf.output("blob");
    //@ts-ignore
    const name: any = userName;
    const pdfFile = new File(
      [pdfBlob],
      `diagnostico-${name}-${nomeEvento}-${moment().format("dd-mm-yy-hh-ss")}.pdf`,
      { type: "application/pdf" }
    );
    const form = new FormData();

    form.append("file", pdfFile);
    form.append("folderPath", "diagnosticos");

    const options = {
      method: "POST",
      url: "https://scan360-backend.azurewebsites.net/uploadGrafico",
      data: form,
    };

    try {
      const response = await axios.request(options);
      console.log(response.data, "[RESULTADO UPLOAD]");
      setLoading(true);

      const postData = {
        fileLink: response.data.fileLink,
        name: name,
        relatorioPorArea: relatorioPorArea,
        telephone: userPhone || '9999999999',
        email: userEmail
      };

      try{
        console.log(postData);
        axios.post('https://s2.leads8.com.br/webhook/bc06f29e-dc34-4c77-8576-997763c6535c', postData)
        .then((webhookResponse) => {
          console.log('Webhook post successful:', webhookResponse.data);
        })
        .catch((webhookError) => {
          console.error('Webhook post error:', webhookError);
        });

      }catch(e){
        console.log(e,"FALHA AO ENVIAR API CHATPRO")
      }

      
      if (response.data.fileLink) {
        setFileLink(response.data.fileLink);
        await updateUser(userId, response.data.fileLink);

        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
    // Swal.fire({
    //   title: 'Sucesso!',
    //   text: 'O diagnóstico será enviado! Obrigado.',
    //   icon: 'success',
    //   confirmButtonText: 'Ok'
    // });
    setTimeout(() => {
    //  pdf.save(pdfPath);
    }, 1500);
  }

  useEffect(()=>{
    handleStyleAnimation();
  },[swiperQuizz.current?.realIndex])

  const handleStyleAnimation = () => {
    console.log(swiperQuizz.current?.realIndex,"INDEX")
    let style;
      
          style={
            animation: `fadeInSlideUp 1s ease-out`,
          };
  
    return style;
    
  }

  const handleAnimation = () => {
    let style = {};
    if(swiperQuizz.current?.realIndex === 4 || swiperQuizz.current?.realIndex === 5){
      style = {
        animation: `slideUpFadeIn 1s ease-out`,
        opacity: 1
      };
    }

    

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if(entry.isIntersecting){
        // Run the animation function when the component is visible
              setAnimationStyle({animation:'fadeInSlideUp 2s ease-out'});
            }
    });
    }, { threshold: 0.1 }); // Configure the observer to trigger when at least 10% of the element is visible

        if (swiperQuizz.current) {
          observer.observe(swiperQuizz.current);
        }
        // Clean up the observer on component unmount
        return () => {
          if(swiperQuizz.current){
            observer.disconnect();
          }
        };

}

      
  function gerarRelatorioPorArea() {
    // Agrupar e somar as respostas por categoria
    const somasPorCategoria = Object.entries(answers).reduce(
      (acc: any, [key, value]) => {
        const categoria = key.split("-")[0];
        acc[categoria] = (acc[categoria] || 0) + value;
        return acc;
      },
      {}
    );

    const relatorio: any = {};
    Object.entries(somasPorCategoria).forEach(([categoria, soma]) => {
      let qualificacao;
      if (Number(soma) < 10) {
        qualificacao = qualificacao = todosTextos?.textos.filter((obj: { tela: string; },index: number) => { if(obj.tela === "resultado") return obj})[1].content[0].toString();
      } else if (Number(soma) <= 20) {
        qualificacao = todosTextos?.textos.filter((obj: { tela: string; },index: number) => { if(obj.tela === "resultado") return obj})[1].content[1].toString();
      } else {
        qualificacao = todosTextos?.textos.filter((obj: { tela: string; },index: number) => { if(obj.tela === "resultado") return obj})[1].content[2].toString();
      }
      relatorio[categoria] = { soma, qualificacao };
    });

    return relatorio;
  }


 async function gerarRelatorioPorAreaSimples (categ:string){
    const report = gerarRelatorioPorArea();
    const result = Object.keys(report).filter((key) => report[key].qualificacao === categ);
    return result.join(", ");
 
  }

  async function uploadGrafico(uid: any, chartDados: any) {
    try {
      const pdfPath = `dignostico-${moment().format("dd-mm-YYYY-hhss")}.pdf`;
      await generatePdfFromHtml(pdfPath, chartDados);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  async function searchUser(email?:string) {
    try {
      const options = {
        method: "POST",
        url: "https://scan360-backend.azurewebsites.net/search",
        headers: { "Content-Type": "application/json" },
        data: { email: email || userEmail },
      };
      axios
        .request(options)
        .then(function (response) {
          // console.log(response.data.dados.total, "TOTAL");
          if (response.data.dados.total > 0) {
            setUsername(response.data.dados.results[0].properties.firstname);
            setUserEmail(response.data.dados.results[0].properties.email);
            setUserPhone(response.data.dados.results[0].properties.phone);
            setUserId(response.data.dados.results[0].id);
            return response.data.dados.results[0].id;
          } else {
            return false;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    } catch (e) {}
  }

  async function handleSendUserData() {
    try {
      setLoading(true);
      const user: any = await searchUser(formValues['email']);
      if (user) {
        setUserId(user);
        await updateUser(user);
      } else {
        await createUser();
      }
    } catch (e) {
      setLoading(false);
      console.log("ERRO AO PROCURAR USUÁRIO");
    }
  }

  async function createUser() {
    try {
      console.log(formValues, "FORMVALUES");
      setLoading(true);
      
      const options = {
        method: "POST",
        url: "https://scan360-backend.azurewebsites.net/addLeadByForm",
        headers: { "Content-Type": "application/json" },
        data: {
          properties: {
            email: formValues.email,
            firstname: formValues.fullName || formValues.firstname,
            tipo_de_negocio: formValues.tipo_de_negocio || formValues.businessType || "",
            phone: formValues.telephone || formValues.phone,
            grafico_quizz: "",
            perguntas_quizz: "",
            ja_e_cliente: formValues.ja_e_cliente_evo_!==undefined&&formValues.ja_e_cliente_evo_ ? "Sim" : "Não",
            pontos_fortes: formValues.pontos_fortes || "",
            pontos_de_atencao: "",
            desafios: "",
            lang:defaultLang
          },
        },
      };
      axios
        .request(options)
        .then(function (response) {
          console.log("SALVOU DADOS", response.data);
          setLoading(false);
          swiperQuizz.current.slideNext(1000);
        })
        .catch(function (error) {
          console.error(error);
        });

        let resp:any;
        // if(defaultLang==="pt"){
        //   await getForm("430f7f70-0edb-4166-9575-b0fdbbcfb4d4");
        // }else if(defaultLang==="es"){
        //   await getForm("430f7f70-0edb-4166-9575-b0fdbbcfb4d4");
        // }else if(defaultLang==="en"){
        //   await getForm("130294a0-3176-4d6b-9f01-100faa5f6095");
        // }

        if(formData){
          //@ts-ignore
          setNomeEvento(formData.fieldGroups[9].fields[0].defaultValues[0]);
        }
         
    } catch (e) {
      console.log(e);
    }
  }


  async function updateUser(uid: any, fileUrl?: any) {
    const pontos_de_atencao = await gerarRelatorioPorAreaSimples('PONTOS DE ATENÇÃO');
    const pontos_fortes = await gerarRelatorioPorAreaSimples('PONTOS FORTES');
     console.log(pontos_de_atencao,pontos_fortes,"PONTOS");
    const options = {
      method: "POST",
      url: "https://scan360-backend.azurewebsites.net/updateContactQuizz",
      headers: { "Content-Type": "application/json" },
      data: {
        properties: {
          vid: Number(uid),
          email: formValues.email,
          firstname: formValues.fullName || formValues.firstname,
          tipo_de_negocio: formValues.tipo_de_negocio || formValues.businessType || "",
          phone: formValues.telephone|| formValues.phone,
          grafico_quizz: fileUrl || fileLink,
          ja_e_cliente: formValues.ja_e_cliente_evo_ ? "Sim" : "Não",
          pontos_de_atencao: pontos_de_atencao,
          pontos_fortes: pontos_fortes,
        },
      },
    };
    axios
      .request(options)
      .then(function (response) {
        console.log("[USUÁRIO ATUALIZADO] ", response);
      })
      .catch(function (error) {
        console.error(error);
      });
  }


   const handleCheckboxChange = (event:any) => {
    setFormValues({
      ...formValues,
      ja_e_cliente: (event.target.name&&event.target.name === "sim")
    });
  };

  const swiperProgress =()=>{
    const progressMap:any = {
      4: 10,
      5: 30,
      6: 50,
      7: 60,
      8: 80,
      9: 100
    };
    // Return the progress for the current index, or 0 if the index is not found
    return progressMap[swiperIndex] || 0;
  }
  const getSwiperSlide =()=>{
    const progressMap:any = {
      4: 1,
      5: 2,
      6: 3,
      7: 4,
      8: 5,
      9: 6
    };
    // Return the progress for the current index, or 0 if the index is not found
    return progressMap[swiperIndex] || 0;
  }

  const TextMaskCustom = (props: any) => {
    const { mask, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        mask={mask}
        placeholderChar={'\u2000'}
        showMask={false}
      />
    );
  }
  function getPhoneMask(defaultLang: any) {
    switch (defaultLang) {
      case 'en': // Assuming this is for the USA
        return ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
      case 'es': // Assuming a general format for Spanish-speaking countries
        return ['+', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
      case 'pt': // Assuming this is for Brazil
        return ['+', '5', '5', ' ', '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
      default:
        return ['+', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]; // Default mask
    }
  }
  useEffect(() => {
    checkAllQuestionsAnswered();
  }, [answers, handleNext]);

  useEffect(() => {
    const getFormulario = async () => {
      await getForm("a85d33d6-d05c-4542-8fe7-690f9df2c799");
    }
    getFormulario();
  }, []);

  return (
    
    <>
      <ThemeProvider theme={theme}>
        <style>
          {`
             .inputStyle {
                marginBottom: 20px;
                backgroundColor: white;
                borderBottom: "2px solid white !important";
                color: "white";
              }
              @font-face {
                font-family: 'Poppins';
                src: url(${Poppins}) format('truetype');
              }
              @font-face {
              font-family: 'PoppinsLight';
              src: url(${PoppinsLight}) format('truetype');
              }

              body {
                font-family: 'Poppins', sans-serif;
              }  
              @font-face {
                font-family: 'PoppinsBold';
                src: local('PoppinsBold'), url(${PoppinsBold}) format('truetype');
              }
            * {
              font-family: 'Poppins', Arial;
              } 
              .Strong {
                color: '#8061FF' !important,
                fontFamily: 'PoppinsBold',
                fontWeight:'bolder'
              }
              .inputForm1{
               
              }
              .wrapNavs{
                position: relative;
                bottom: 10px;
                z-index: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                align-content: center;
                width: 100%;
              }
              .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-1633um2-MuiPaper-root {
                  padding: 0;
              }
              .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-7oz6rj-MuiPaper-root {
                  margin: 0;
                  padding: 0;
              }
              body,html{
                background-color:"white";
              }
            `}
        </style>

        {/* TELA INICIAL */}
        <Box
          flex={1}
          style={{
            height:window.innerHeight,
            color:'white',
          }}
        >

          {/* {swiperQuizz.current&&swiperQuizz?.current?.activeIndex > 3 && swiperQuizz?.current?.activeIndex <= 8 ?
          <>
            <Container style={{backgroundColor:"white", border:0, padding:15, display:"flex", flexDirection:"column"}}>
              <Grid container>
                <Grid item xs={11} paddingTop={1.2} paddingRight={1.2}>
                <LinearProgress  sx={{
                    backgroundColor:"#8061ff40"
                 ,
                  '& .MuiLinearProgress-barColorPrimary': {
                    backgroundColor: '#8061ff', // Custom progress bar color
                  },
                }}
                variant="determinate"  value={swiperProgress()||0} />
                </Grid>
                <Grid item xs={1} textAlign={'center'} padding={0}>
                  <Typography variant="body1" color="black" fontWeight={'bold'}>
                  {getSwiperSlide()+"/6"}
                  </Typography>
                
                </Grid>
              </Grid>
              
            </Container>
          </>:null} */}

          <Swiper
            style={{height:'inherit',paddingLeft:5}}
            simulateTouch={false}
            spaceBetween={0}
            slidesPerView={1}
            onSlideChange={async () => {
            
            const contagemResp = countAnsweredQuestions();
             setIndex(swiperQuizz.current?.activeIndex);
              //@ts-ignore
              if (swiperQuizz.current?.activeIndex === 4) {
                if (
                  !formValues.email ||
                  !formValues.phone ||
                  !formValues.firstname
                ) {
                  handlePrev();
                  return Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Preencha todos os campos para continuar.",
                  });
                } else {
                 // handleSendUserData();
                }
              }

              const fireError = ()=>{
               return Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Preencha todos os campos para continuar.",
                });
              }
              if(swiperQuizz.current?.activeIndex===4){
                const introQuizzText = todosTextos?.textos.find((obj: { tela: string; }) => obj.tela === "introQuizz")?.content;
                if (introQuizzText) {
                  Swal.fire({
                    title: 'Introdução',
                    text: introQuizzText,
                    icon: 'info',
                    confirmButtonText: 'Ok'
                  });
                }
              }
            
              if(swiperQuizz.current?.activeIndex===5){
                if(contagemResp<3){
                  handlePrev();
                  fireError();
                }
              }else if(swiperQuizz.current?.activeIndex===6){
                if(contagemResp<6){
                  handlePrev();
                  fireError();
                }
              }else if(swiperQuizz.current?.activeIndex===7){
                if(contagemResp<9){
                  handlePrev();
                  fireError();
                }
              }else if(swiperQuizz.current?.activeIndex===8){
                if(contagemResp<12){
                  handlePrev();
                  fireError();
                }
              }else if(swiperQuizz.current?.activeIndex===9){
                if(contagemResp<15){
                  handlePrev();
                  fireError();
                }
                await searchUser();
              }else if (swiperQuizz.current?.realIndex === 10) {
                const check = await checkAllQuestionsAnswered();
                if (!check) {
                  handlePrev();
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Responda todas as perguntas para continuar.",
                  });
                } 
                await generateChartData(answers).then(async(chartDados)=>{
                  await uploadGrafico(userId, chartDados);
                });
              }
              // console.log(swiperQuizz.current?.realIndex);
              // if (swiperQuizz.current?.realIndex === 9) {
              //   setIsExploding(true);
              //   setTimeout(() => {
              //     setIsExploding(false);
              //   }, 3000);
              // }

              if (swiperQuizz.current?.realIndex === 1) {
                setShowNav(true);
              }
              if (
                swiperQuizz.current?.realIndex === 0 
              ) {
                setShowNav(false);
              }
            }}
            onSwiper={(swiper: any) => {
              //@ts-ignore
              swiperQuizz.current = swiper;
            }}
          >
            
            <SwiperSlide
                style={{ 
                  backgroundColor: "#000", 
                  paddingTop: 75, 
                  display: "flex", // Use flex layout
                  justifyContent: "center", // Center horizontally
                  alignItems: "center", // Center vertically
                  height: "100vh", // Make SwiperSlide full viewport height
                  overflow: "hidden" // Prevent overflow
                }}
                onClick={() => handleNext()}
              >
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  className="swiper-background-video"
                  style={{
                    maxWidth: "100%", // Limit video width to prevent overflow
                    maxHeight: "100vh", // Limit video height to viewport height
                    objectFit: "contain" // Preserve aspect ratio while fitting within the container
                  }}
                >
                  <source src={introVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </SwiperSlide>
           

            {/* SELECIONA O IDIOMA ####################################  */}

            <SwiperSlide
              style={{ backgroundColor: "#000", height: "auto", overflow:'hidden', display:"flex", alignItems:"center",justifyContent:"center" }}
            >
              <Box display={"flex"} gap={3} flexDirection={"column"}>
                <LangBtn
                  onClick={() => {
                    setLanguage("pt");
                  }}
                >
                  Português
                </LangBtn>
                <LangBtn
                  onClick={() => {
                    setLanguage("es");
                  }}
                >
                  Español
                </LangBtn>
                <LangBtn
                  onClick={() => {
                    setLanguage("en");
                  }}
                >
                  English
                </LangBtn>
              </Box>
            </SwiperSlide>

            {/* INTRODUÇÃO ####################################  */}
            <SwiperSlide
              style={{ backgroundColor: "#000",paddingTop:55, display:"flex",justifyContent:"center",alignItems:"flex-start", height:'inherit' }}
            >
              <Container>
                <Box padding={1} maxWidth={1000} margin={"auto"}>

                  {swiperQuizz.current?.activeIndex===2 &&
                  <>
                      <img src={img_intro} style={{
                      width:'90%',
                      display:"flex",
                      margin:"0 auto",
                      marginBottom:15,
                      animation: 'fadeInSlideUp 2s ease-in-out'
                      }} />
                  
                  <Box
                      sx={{
                        display: 'flex',
                        gap:2,
                        flexDirection: {
                          xs: 'column', // Note the corrected spelling here
                          md: 'row'
                        },
                      }}
                    >
                  <Typography
                        component={"h2"}
                        sx={{
                          animation: 'fadeInSlideUp 2s ease-out',
                          fontSize: '54px', // Adjusted fontSize for mobile screens
                          color: "white",
                          textAlign:"left",
                          "@media (max-width: 600px)": {
                            fontSize: "30px", // New fontSize for mobile
                          }
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            "" +
                            todosTextos?.textos.find((obj: { tela: string; }, key:number) => key===0&&obj.tela === "bemvindo")
                              ?.content.replace(/\n/g, "<br>"),
                        }}
                      />
                  <Typography
                        component={"h1"}
                        style={{animation: 'fadeInSlideUp 2s ease-out', color: "white",textAlign:"left" }}
                        dangerouslySetInnerHTML={{
                          __html:
                            "" +
                            todosTextos?.textos.find((obj: { tela: string; }, key:number) => key===1&&obj.tela === "bemvindo")
                              ?.content.replace(/\n/g, "<br/>"),
                        }}
                      />
                  </Box>
                    
                  </>}
                  
                </Box>
                <br></br>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={50}
                >
                  {buttonSwiper()}
                </Box>
              </Container>
            </SwiperSlide>

            {/* FORMULARIO COM DADOS DO LEAD */}
            {/* /////////////////////////////////////////////////////////////////////////////////////////// */}
            <SwiperSlide
              className={'swiperSlideCenter'}
              style={{backgroundColor: "#000",display:"flex",justifyContent:"center", height:'auto',paddingTop:'7%'}}
            > 
              <Container maxWidth="sm" style={{ backgroundColor: "#000", color: "white", padding: "20px", borderRadius: "8px" }}>
                <Box
                  style={{
                    marginBottom: "20px",
                    color: "white",
                    background: "linear-gradient(45deg, #E43AEB 30%, #8261FF 90%)",
                    WebkitBackgroundClip: "border-box",
                    backgroundClip: "border-box",
                    borderRadius:5,
                    padding:5,
                    paddingLeft:15  
                  }}
                >
                  <Typography variant="h6" fontSize={16}>
                    Preencha o formulário e garanta já seus materiais!
                  </Typography>
                </Box>
                <br></br>
                <Grid container spacing={3}>
                  {/* Dynamically generated form fields */}
                 
                  {formData&&formData?.fieldGroups.map((group:any) => group.fields.map((field:any, key:number) => {
                    switch (field.fieldType) {
                      case 'phone':
                        return (
                          <Grid item xs={12} key={key}>
                            <InputLabel style={{ color: "white" }}>{field.label}{field.required ? '*' : ''}</InputLabel>
                            <Input
                              fullWidth
                              name={field.name}
                              //@ts-ignore
                              defaultValue={formValues[field.name]}
                              onBlur={handleFormChange}
                              style={{ color: "white", borderBottom: "1px solid white" }}
                              autoComplete="off"
                              inputComponent={TextMaskCustom} // Use TextMaskCustom for phone input
                              inputProps={{
                                mask: getPhoneMask(defaultLang), // Get the phone mask based on the selected language
                              }}
                            />
                          </Grid>
                        );
                      case 'email':
                      case 'single_line_text':
                      case 'multi_line_text':
                        return (
                          <Grid item xs={12} key={key}>
                            <InputLabel style={{ color: "white" }}>{field.label}{field.required ? '*' : ''}</InputLabel>
                            <Input
                              fullWidth
                              name={field.name}
                              //@ts-ignore
                              defaultValue={formValues[field.name]}
                              onBlur={handleFormChange}
                              style={{ color: "white", borderBottom: "1px solid white" }}
                              autoComplete="off"
                              type={field.fieldType === 'email' ? 'email' : 'text'}
                            />
                          </Grid>
                        );
                      case 'dropdown':
                        return (
                          <Grid item xs={12}>
                            <InputLabel style={{ color: "white" }}>{field.label}</InputLabel>
                            <Select
                              fullWidth
                              name={field.name}
                              //@ts-ignore
                              value={formValues[field.name]}
                              onChange={handleFormChange}
                              style={{ color: "white", borderBottom: "1px solid white" }}
                            >
                              {field.options.map((option:any) => (
                                <MenuItem value={option.value}>{option.label}</MenuItem>
                              ))}
                            </Select>
                          </Grid>
                        );
                      case 'radio':
                        return (
                          <Grid item xs={12}>
                            <FormControl component="fieldset" fullWidth variant="standard" style={{ color: "white" }}>
                              <FormLabel component="legend" style={{color:'white'}}>{field.label}</FormLabel>
                              <RadioGroup
                                row
                                name={field.name}
                                //@ts-ignore
                                value={formValues[field.name]}
                                onChange={handleFormChange}
                              >
                                {field.options.map((option:any) => (
                                  <FormControlLabel
                                    value={option.value}
                                    control={<Radio style={{ color: "white" }} />}
                                    label={option.label}
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        );
                      // Add cases for other field types as needed
                      default:
                        return null;
                    }
                  }))}
                  {/* Submit button */}
                  <Grid item xs={12} textAlign={'center'}>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#4BEE3B", fontWeight:'bold', color: "black", width: "200px", borderRadius:50, padding:15 }}
                      onClick={() => handleSendUserData()}
                    >
                      {loading ? (
                          <CircularProgress size={24} style={{ color: "black" }} />
                        ) : (
                          "Iniciar agora!"
                        )}
                    </Button>
                  </Grid>
                </Grid>
              </Container>     
            </SwiperSlide>


            {/* PERGUNTAS - ETAPA 1 */}
            <SwiperSlide
              style={{display: 'flex',flexDirection: 'column',backgroundColor: "#FFF", paddingTop:75, paddingLeft:5, height: 'auto'}}>
      
                <div style={{animation:'fadeInSlideUp 2s ease-out', display:'flex',flexDirection:'column', justifyContent:'center',padding:'0 5px 0 2px  '}}>
           
                {/* <QuizzHeader paddingX={2} variant={"h5"} color={"black"}>  
                {todosTextos?.textos.find((obj: { tela: string; }) => obj.tela === "introQuizz")?.content}
                </QuizzHeader>
                <br></br> */}
                {
                  //@ts-ignore
                  questions && Object.assign([], questions).content && Object.assign([], questions).content.map(
                      (obj: any, areaKey: any) => {
                        if (
                          obj.area === "Cultura e Liderança" ||
                          obj.area === "Cultura y Liderazgo" ||
                          obj.area === "Culture and Leadership"
                        ) {
                          return obj.perguntas.map((item: any, index: any) => {
                            return (
                              <RatingScale
                                question={item}
                                 onRatingSelect={(val: any) =>
                                  handleInputChange(areaKey, index, val)
                                }
                              />
                            );
                          });
                        }
                      }
                    )
                }
                {buttonSwiper()}
              </div>
            
            </SwiperSlide>

 
            {/* PERGUNTAS - ETAPA 2 */}
          
            <SwiperSlide
              style={{display: 'flex',flexDirection: 'column',backgroundColor: "#FFF", height: "auto", paddingTop:75 }}>
      
              <div style={{animation:'fadeInSlideUp 2s ease-out', display:'flex', height:'fit-content',flexDirection:'column', justifyContent:'center',padding:'0 5px 0 2px  '}}>
           
               {
                      //@ts-ignore
                      questions && Object.assign([], questions).content && Object.assign([], questions).content.map(
                          (obj: any, areaKey: any) => {
                            if (
                              obj.area === "Experiência do cliente" ||
                              obj.area === "Experiencia del Cliente" ||
                              obj.area === "Customer Experience"
                            ) {
                              return obj.perguntas.map((item: any, index: any) => {
                                return (
                                  <RatingScale
                                    question={item}
                                    onRatingSelect={(val: any) =>
                                      handleInputChange(areaKey, index, val)
                                    }
                                  />
                                );
                              });
                            }
                          }
                        )
                    }
                {buttonSwiper()}
               </div>
             
            </SwiperSlide>

            {/* PERGUNTAS - ETAPA 3 */}
            <SwiperSlide
              style={{display: 'flex',flexDirection: 'column',backgroundColor: "#FFF", height: "auto", paddingTop:75, }}>
      
                <div style={{animation:'fadeInSlideUp 2s ease-out', display:'flex', height:'fit-content',flexDirection:'column', justifyContent:'center',padding:'0 5px 0 2px  '}}>
           
                {
                    //@ts-ignore
                    questions && Object.assign([], questions).content && Object.assign([], questions).content.map(
                        (obj: any, areaKey: any) => {
                          if (
                            obj.area === "Gestão" ||
                            obj.area === "Gestión" ||
                            obj.area === "Management"
                          ) {
                            return obj.perguntas.map((item: any, index: any) => {
                              return (
                                <RatingScale
                                  question={item}
                                  onRatingSelect={(val: any) =>
                                    handleInputChange(areaKey, index, val)
                                  }
                                />
                              );
                            });
                          }
                        }
                      )
                  }
                  {buttonSwiper()}

              </div>
      
            </SwiperSlide>

            {/* PERGUNTAS - ETAPA 4 */}
            <SwiperSlide
              style={{display: 'flex',flexDirection: 'column',backgroundColor: "#FFF", height: "auto", paddingTop:75, }}>
      
                <div style={{animation:'fadeInSlideUp 2s ease-out', display:'flex', height:'fit-content',flexDirection:'column', justifyContent:'center',padding:'0 5px 0 2px  '}}>
           
                  {
                      //@ts-ignore
                      questions && Object.assign([], questions).content && Object.assign([], questions).content.map(
                          (obj: any, areaKey: any) => {
                            if (
                              obj.area === "Inovação e Tecnologia" ||
                              obj.area === "Innovation and Technology" ||
                              obj.area === "Innovación y Tecnología"
                            ) {
                              return obj.perguntas.map((item: any, index: any) => {
                                return (
                                  <RatingScale
                                    question={item}
                                    onRatingSelect={(val: any) =>
                                      handleInputChange(areaKey, index, val)
                                    }
                                  />
                                );
                              });
                            }
                          }
                        )
                    }
                    {buttonSwiper()}
                </div>
              
            </SwiperSlide>

            {/* PERGUNTAS - ETAPA 5 */}
            <SwiperSlide
              style={{display: 'flex',flexDirection: 'column',backgroundColor: "#FFF", height: "auto", paddingTop:75,}}>
      
                  <div style={{animation:'fadeInSlideUp 2s ease-out', display:'flex', height:'fit-content',flexDirection:'column', justifyContent:'center',padding:'0 5px 0 2px  '}}>
           
                  {
                        //@ts-ignore
                        questions && Object.assign([], questions).content && Object.assign([], questions).content.map(
                            (obj: any, areaKey: any) => {
                              if (obj.area === "Marketing") {
                                return obj.perguntas.map((item: any, index: any) => {
                                  return (
                                    <RatingScale
                                      question={item}
                                      onRatingSelect={(val: any) =>
                                        handleInputChange(areaKey, index, val)
                                      }
                                    />
                                  );
                                });
                              }
                            }
                          )
                      }
                      {buttonSwiper()}
                    </div>
              
            </SwiperSlide>

            {/* PERGUNTAS - ETAPA 6 */}
            <SwiperSlide
              style={{display: 'flex',flexDirection: 'column',backgroundColor: "#FFF", height: "auto", paddingTop:75 }}>
      
                <div style={{animation:'fadeInSlideUp 2s ease-out', display:'flex', height:'fit-content',flexDirection:'column', justifyContent:'center',padding:'0 5px 0 2px  '}}>
                 {
                            //@ts-ignore
                            questions &&  Object.assign([], questions).content && Object.assign([], questions).content.map(
                                (obj: any, areaKey: any) => {
                                  if (
                                    obj.area === "Vendas" ||
                                    obj.area === "Sales" ||
                                    obj.area === "Ventas"
                                  ) {
                                    return obj.perguntas.map((item: any, index: any) => {
                                      return (
                                        <RatingScale
                                          question={item}
                                          onRatingSelect={(val: any) =>
                                            handleInputChange(areaKey, index, val)
                                          }
                                        />
                                      );
                                    });
                                  }
                                }
                              )
                          }
                          {buttonSwiper()}
                        </div>
                 
            </SwiperSlide>

            {/* HORA DO SORTEIO */}

            {/* <SwiperSlide
              style={{ backgroundColor: "#7256fc", minHeight:'100vh', height: "100%",display:"flex", alignItems:"flex-start" }}>        

                    <Container style={{animation:'fadeInSlideUp 2s ease-out', display:'flex',flexDirection:'column', justifyContent:'center', height:'100%', alignItems:'center'}}>
                        <Box
                          style={{
                            maxWidth: "800px",
                            width:'100%',
                            flex: 1,
                            paddingTop:5,                  
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems:"center",
                            textAlign:"center",
                            alignContent:"center",
                            zIndex: 10000,
                          }}
                        >
                          <Box style={{ position: "relative"}}>
                            <Typography variant="h4" style={{zIndex:1000000,paddingTop:130 }}>
                            {todosTextos?.textos.filter((obj: { tela: string; }) => obj.tela === "sorteio")[2]?.content}
                            </Typography>
                          </Box>
                          
                          <Box
                            style={{ position: "relative", top: -30,overflow:"hidden", left: 0,right:0, zIndex: 0 }}
                          >
                            <VideoComponent videoSrc={require("./assets/roleta.mp4")} />    
                            
                          </Box>
                          </Box>
                      </Container>
                
            </SwiperSlide> */}

            {/* <SwiperSlide
              style={{ backgroundColor: "#7256fc", height: "auto",display:"flex",justifyContent:"center",textAlign:"center" }}>        
              
              <Container style={{display:'flex',flexDirection:'column', justifyContent:'center',width:'100%', height:'100%', alignItems:'center'}}>
                <Typography variant="h1">
                  {defaultLang==="pt"&&"Parabéns, você ganhou"}
                  {defaultLang==="en"&&"Congratulations, you've won"}
                  {defaultLang==="es"&&"FELICIDADES, GANASTE"}                  
                </Typography>
                <br></br>
                <br></br>
                <Typography variant="h3">{gift}</Typography>
                <br></br>
                <br></br>
                <ResultsBtn
                  onClick={async () => {
                   
                    setTimeout(async () => {
                      const chartDados = await generateChartData(answers);
                      await uploadGrafico(userId, chartDados);
                    }, 500);
                    await handleNext();
                  }}
                >
                 
                 {todosTextos?.textos.find((obj: { tela: string; }) => obj.tela === "verResultado")?.content}
                </ResultsBtn>
              </Container>
              {isExploding && <Confetti width={width} height={height} />}

            </SwiperSlide> 
  */}
            <SwiperSlide style={{overflow:'auto', height:'auto', justifyItems:'flex-start', minHeight: '100vh',backgroundColor:"#FFF",display:'flex', flexDirection:"column",alignItems:"center", justifyContent:"flex-start",paddingTop:15 }}>
              <Box padding={3} paddingTop={5}>
                <Container>
                  <QuizzHeader variant="body1" color={"black"}>
                   {todosTextos?.textos.find((obj: { tela: string; }) => obj.tela === "resultado")?.content}
                  </QuizzHeader>
                </Container>
                <Container>
                  <Grid container xs={12}>
                    <Grid item xs={12} sm={8} margin={0}>
                      <SpiderChart data={chartData} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignContent={"center"}
                    >
                      <Box
                        style={{                        
                          color: "black",
                          textAlign: "left",
                          margin:15,
                          wordWrap: "break-word",
                          display: 'flex',
                          flexDirection: "column"
                        }}
                      >
                        <Grid container>
                        
                        <Grid xs={12} sm={6} padding={0}>
                              <ReportComponent report={report} />
                        </Grid>
                        <Grid xs={12} sm={6}>
                       
                            <Typography variant="body1">
                            {todosTextos?.textos.filter((obj: { tela: string; },index: number) => { if(obj.tela === "resultado") return obj})[2].content.toString()}
                            </Typography>
                        
                        </Grid>
                      </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container xs={12}>
                    <Grid item xs={12}  margin={'auto'} textAlign={'center'}>
                      
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => window.open("https://wa.link/6tbr6w", "_blank")}
                      disabled={btnLoading}
                      style={{
                        width: 280,
                        padding: 2,
                        marginBottom:5,
                        backgroundColor: '#25D366',
                        border: 0,
                        color: 'white',
                        fontWeight: 'bold',
                        margin: '10px auto',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex', // Ensure the button contents use flexbox
                        flexDirection: 'row', // Arrange items in a row
                        textAlign: 'center', // Center text for any fallback
                      }}
                    >                        
                      <img 
                        width="30px" 
                        src={img_whats} 
                        alt="WhatsApp" 
                        style={{ 
                          marginRight: '10px', // Add space between image and text
                          verticalAlign: 'middle' // Align image vertically
                        }} 
                      />
                      Enviar por Whatsapp
                    </Button>
                      
                      {/* <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleDownload}
                        disabled={btnLoading}
                        style={{width:280, paddingBottom:3, backgroundColor: '#25D366',
                          border: 0,
                          color: 'white',
                          fontWeight: 'bold',
                          margin: '10px auto'
                        }}
                      >                        
                        {btnLoading ? <CircularProgress size={15} style={{margin:5}} /> : "Enviar por Whatsapp"}
                      </Button> */}
                    </Grid>                   
                  </Grid>
                </Container>
                <br></br>
                {buttonSwiper()}
              </Box>
            </SwiperSlide>

            <SwiperSlide style={{ backgroundColor: "black",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center", height:'inherit', }}>
            
                <Container style={{display:'flex',flexDirection:'column', justifyContent:'center', height:'100%', textAlign:"center", alignItems:'center'}}>
                  <img
                    style={{ width:'100%', maxWidth:1200, margin: "0 auto" }}
                    src={img_final}
                  />
                  <br></br>
                  <DiagnosticBtn
                    onClick={() => {
                      if(formValues['ja_e_cliente_evo_'] === "Sim"){
                        window.open("https://softwareevo.com/black-friday-2024-clientes", "_blank");
                      }else{
                        window.open("https://w12.com.br", "_blank");
                      }
                      
                    }}
                    style={{ color: "white", padding: "10px,25px" }}
                  >
                    ENCERRAR
                  </DiagnosticBtn>
                </Container>
              
            </SwiperSlide>
          </Swiper>
        </Box>
      </ThemeProvider>
    </>
  );
}
